<template>
  <div class="login-container">
    <div class="login-form">
      <h3>
        <img src="../assets/logo.png" width="269" height="65"/>
      </h3>
      <el-card class="box-card">
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane label="用户名登录" name="first">
            <el-form
              class="card-box"
              autocomplete="on"
              :model="loginForm"
              :rules="loginRules"
              ref="loginForm"
              label-position="left"
            >
              <el-form-item prop="userName">
                <i class="el-icon-user-solid"></i>
                <el-input
                  name="userName"
                  type="text"
                  v-model="loginForm.userName"
                  autocomplete="on"
                  placeholder="请输入用户名"
                />
              </el-form-item>
              <el-form-item prop="password">
                <i class="el-icon-lock"></i>
                <el-input
                  name="password"
                  :type="pwdType"
                  @keyup.enter.native="handleLogin"
                  v-model="loginForm.password"
                  autocomplete="on"
                  placeholder="请输入密码"
                />
                <i class="el-icon-view" @click="showPwd"></i>
              </el-form-item>
              <el-button
                type="primary"
                style="width:100%;margin-bottom:30px;"
                :loading="loading"
                @click.native.prevent="handleLogin"
                class="blue-btn"
              >登录</el-button>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="微信扫码登录" name="second">
            <div style="text-align:center;" v-loading="loading">
              <img :src="qrcodeUrl" style="width:300px;fit-object:cover;"/>
              <div>扫码后，页面将自动跳转......</div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>
<script>
import userApi from "@/api/sys/user";
import Constant from "@/constant";
import { Message } from "element-ui";
import { setToken} from '@/utils/auth'

export default {
  name: "login",
  data() {
    return {
      activeName: "first",
      rnd : Math.ceil(Math.random()*1000000),
      loginForm: {
        userName: "",
        password: ""
      },
      loginRules: {
        userName: [
          { required: true, message: "请填写用户名", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请填写密码", trigger: "blur" },
          {
            type: "string",
            min: 2,
            message: "密码长度不能小于2位",
            trigger: "blur"
          }
        ]
      },
      pwdType: "password",
      loading: false,
      redirect: undefined,
      qrcodeUrl: "",
      queryScanResultHandler: 0
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
        }
      },
      immediate: true
    }
  },
  methods: {
    showPwd() {
      if (this.pwdType === "password") {
        this.pwdType = "";
      } else {
        this.pwdType = "password";
      }
    },
    handleLogin() {
      var self = this;

      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;

          self.$store
            .dispatch("user/login", self.loginForm)
            .then((result) => {
              self.loading = false;

              if(result){
                self.$router.push({ path: this.redirect || "/home" });
              }
            })
            .catch(error => {
              self.loading = false;
              // self.$message.error(error || 'Has Error')

              self.$message.error(error);

              // self.$notify({
              //   title: "系统提示",
              //   message: error || "Has Error",
              //   type: "warning"
              // });
            });
        }
      });
    },
    showQrcode() {
      this.loading = true;

      userApi.scanLogin(this.rnd).then(response=>{
        var jsonData = response.data;
        this.loading = false;

        if(jsonData.result){
          this.qrcodeUrl = jsonData.data;
        }
        else{
          this.$message.warning(jsonData.message);
        }
      })

      this.queryScanResult();
    },
    queryScanResult() {
      userApi.queryScanResult(this.rnd).then(response=>{
        var jsonData = response.data;

        if(jsonData.result){
          var token = jsonData.data;

          //写vuex状态 
          this.$store.commit("user/SET_TOKEN", token);
          
          //写cookie
          setToken(token);

          this.$router.push({ path: "/home" });
        }
        else{
          if(this.activeName=="second"){
            setTimeout(() => {
              this.queryScanResult();
            }, 3000);
          }
        }
      });
    },
    handleTabClick(tab, event) {
      if(this.activeName=='second'){
        this.showQrcode();
      }
    }
  },
  created() {
    // window.addEventListener('hashchange', this.afterQRScan)
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  mounted() {}
};
</script>

<style rel="stylesheet/scss" lang="scss">
@import "src/styles/mixin.scss";
$bg:rgba(242, 247, 253, 1);
$icon_color: rgba(207,134,146,1);
$text_color: black;

.login-container {
  @include relative;
  height: 100vh;
  background-color: $bg;
  background-image:url('../assets/login_bg_element.png');
  background-size:615px 258px;
  background-position: bottom right;
  background-repeat: no-repeat;

  h3{
    text-align:center;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #293444 inset !important;
    -webkit-text-fill-color: #fff !important;
  }

  input {
    background: transparent;
    border: 0px;
    -webkit-appearance: none;
    border-radius: 0px;
    padding: 12px 5px 12px 15px;
    color: $text_color;
    height: 47px;
  }
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;
  }
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
  }

  .title {
    font-size: 18px;
    font-weight:normal;
    color: $text_color;
    margin: 0px auto 20px auto;
    text-align: center;
  }

  .login-form {
    position: absolute;
    left: 0;
    right: 0;
    width: 400px;
    padding: 35px 35px 15px 35px;
    margin: 60px auto;
  }

  .box-card{

  }

  .login-form i {
    color: $icon_color;
    font-size: 14px;
  }

  @media only screen and (max-width: 768px) {
    .login-form {
      position: absolute;
      left: 0;
      right: 0;
      width: 320px;
      padding: 15px 15px 15px 15px;
      margin: 120px auto;
    }
  }

  .el-form-item {
    border: 1px solid rgba(228, 228, 228, 1);
    background: rgba(242, 242, 242, 1);
    border-radius: 5px;
    padding-left:5px;
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    cursor: pointer;
  }
  .thirdparty-button {
    position: absolute;
    right: 35px;
    bottom: 28px;
  }
  .green-btn{
    background-color:#64a63c;
    border-color: #64a63c;
  }
  .blue-btn{
    background-color:#3385FF;
    border-color: #3385FF;
  }
}
</style>
